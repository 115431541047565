/* eslint-disable @typescript-eslint/no-explicit-any */

// import node_modules
import * as PropTypes from 'prop-types';
import * as React from 'react';

// import types
import { NextPage } from 'next';

// import components
import GlobalHead from '../components/meta/GlobalHead';

// App component
const StorylinerAPI: NextPage<{ Component: React.FunctionComponent; pageProps: any }> = ({
  Component,
  pageProps,
}) => (
  <>
    <GlobalHead />
    <Component {...pageProps} />
  </>
);

StorylinerAPI.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

StorylinerAPI.displayName = 'StorylinerAPI';

export default StorylinerAPI;
