// cookie config

const isPreviewBranch = !(
  process.env['VERCEL_GIT_COMMIT_REF'] === 'development' ||
  process.env['VERCEL_GIT_COMMIT_REF'] === 'staging' ||
  process.env['VERCEL_GIT_COMMIT_REF'] === 'main'
);

const isPreview = process.env['VERCEL_ENV'] === 'preview' && isPreviewBranch;

const storylinerDomain =
  process.env['NODE_ENV'] === 'development'
    ? 'localhost'
    : process.env['STORYLINER_ROOT_URL']?.replace(/^https?:\/\//, '');

export const cookieDomain = isPreview ? process.env['VERCEL_URL'] : storylinerDomain;

export const magicSecretKey = process.env['MAGIC_SECRET_KEY'];

export const TOKEN_NAME = 'storylinerToken';

export const TOKEN_SECRET = process.env['STORYLINER_API_TOKEN_SECRET'] as string;

export const MAX_AGE = 60 * 60 * 24 * 30; // 30 days
