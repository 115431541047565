/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */

// import node_modules
import * as React from 'react';
import Head from 'next/head';

// import config
import { storylinerApiUrl } from '../../config';

// Global head component
const GlobalHeadTags: React.FunctionComponent = () => {
  // META TAGS
  const metaTags = React.useMemo(
    () => [
      // content
      { key: 'charSet', charSet: 'utf-8' },
      {
        key: 'viewport',
        name: 'viewport',
        content:
          'minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover',
      },
      {
        key: 'msapplication-TileImage',
        name: 'msapplication-TileImage',
        content: `${storylinerApiUrl}/ms-icon-144x144.png`,
      },

      // prerender
      { key: 'prerender', name: 'fragment', content: '!' },

      // noindex
      { key: 'noindex', name: 'robots', content: 'noindex, nofollow' },
    ],
    [],
  );

  // LINKS
  const linkTags = React.useMemo(
    () => [
      // icons
      {
        href: `${storylinerApiUrl}/safari-pinned-tab.svg`,
        key: 'mask-icon',
        rel: 'mask-icon',
        color: '#2F2F2E',
      },
      {
        href: `${storylinerApiUrl}/favicon.ico`,
        key: 'shortcut icon',
        rel: 'shortcut icon',
        type: 'image/x-icon',
      },
      {
        href: `${storylinerApiUrl}/favicon.png`,
        key: 'icon',
        rel: 'icon',
        type: 'image/x-icon',
      },
      {
        href: `${storylinerApiUrl}/apple-icon.png`,
        key: 'apple-touch-icon',
        rel: 'apple-touch-icon',
      },
      {
        href: `${storylinerApiUrl}/apple-icon-57x57.png`,
        key: 'apple-touch-icon-57x57',
        rel: 'apple-touch-icon',
        sizes: '57x57',
      },
      {
        href: `${storylinerApiUrl}/apple-icon-60x60.png`,
        key: 'apple-touch-icon-60x60',
        rel: 'apple-touch-icon',
        sizes: '60x60',
      },
      {
        href: `${storylinerApiUrl}/apple-icon-72x72.png`,
        key: 'apple-touch-icon-72x72',
        rel: 'apple-touch-icon',
        sizes: '72x72',
      },
      {
        href: `${storylinerApiUrl}/apple-icon-76x76.png`,
        key: 'apple-touch-icon-76x76',
        rel: 'apple-touch-icon',
        sizes: '76x76',
      },
      {
        href: `${storylinerApiUrl}/apple-icon-114x114.png`,
        key: 'apple-touch-icon-114x114',
        rel: 'apple-touch-icon',
        sizes: '114x114',
      },
      {
        href: `${storylinerApiUrl}/apple-icon-120x120.png`,
        key: 'apple-touch-icon-120x120',
        rel: 'apple-touch-icon',
        sizes: '120x120',
      },
      {
        href: `${storylinerApiUrl}/apple-icon-144x144.png`,
        key: 'apple-touch-icon-144x144',
        rel: 'apple-touch-icon',
        sizes: '144x144',
      },
      {
        href: `${storylinerApiUrl}/apple-icon-152x152.png`,
        key: 'apple-touch-icon-152x152',
        rel: 'apple-touch-icon',
        sizes: '152x152',
      },
      {
        href: `${storylinerApiUrl}/apple-icon-167x167.png`,
        key: 'apple-touch-icon-167x167',
        rel: 'apple-touch-icon',
        sizes: '167x167',
      },
      {
        href: `${storylinerApiUrl}/apple-icon-180x180.png`,
        key: 'apple-touch-icon-180x180',
        rel: 'apple-touch-icon',
        sizes: '180x180',
      },
      {
        href: `${storylinerApiUrl}/favicon.png`,
        key: 'icon-192x192',
        rel: 'icon',
        sizes: '192x192',
        type: 'image/png',
      },
      {
        href: `${storylinerApiUrl}/favicon-16x16.png`,
        key: 'icon-16x16',
        rel: 'icon',
        sizes: '16x16',
        type: 'image/png',
      },
      {
        href: `${storylinerApiUrl}/favicon-32x32.png`,
        key: 'icon-32x32',
        rel: 'icon',
        sizes: '32x32',
        type: 'image/png',
      },
      {
        href: `${storylinerApiUrl}/favicon-96x96.png`,
        key: 'icon-96x96',
        rel: 'icon',
        sizes: '96x96',
        type: 'image/png',
      },
    ],
    [],
  );

  // set head
  return (
    <Head>
      {metaTags.map(({ key, ...metaTag }) => (
        <meta key={key} {...metaTag} />
      ))}
      {linkTags.map(({ key, ...linkTag }) => (
        <link key={key} {...linkTag} />
      ))}
    </Head>
  );
};

export default GlobalHeadTags;
